const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load Content Builder from
 * @param {object=} options - Options to initialize Content Builder with
 * @param {function=} onReady - Callback to call once Content Builder is ready
 * @constructor
 */
const Grid = function (element, stack, options = {}, onReady = function () {}) {
	const finalOptions = $.extend(true, {}, this.defaults, options);
	this.options = finalOptions;

	const url = this._generateUrl(stack, finalOptions.fullContentBuilder);

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

Grid.prototype = {
	constructor: Grid,

	defaults: {
		actions: undefined,
		allowFolderCrud: true,
		allowUpload: true,
		columns: undefined,
		defaultView: undefined,
		fullContentBuilder: false,
		multi: true,
		onDeselected: null,
		onLoad: null,
		onSelected: null,
		showCreate: true,
		showEmailHowTo: false,
		showHeader: true,
		showImport: false,
		showItemActions: true,
		title: undefined
	},

	/**
	 * Generates the CBaaS URL based on the current url/stack
	 *
	 * @return {string}
	 * @private
	 */
	_generateUrl: function (stack, fullContentBuilder) {
		// The full ContentBuilder app is at /contentbuilder
		// Contentmain is located at /contentmain
		const _deepLink = fullContentBuilder ? 'contentbuilder' : 'contentmain';
		return Url.GenerateUrl(stack, _deepLink);
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		this.messenger.destroy();
	},

	clearSelectedItems: function (callback) {
		this.messenger.callMethod('clearSelectedItems', [callback]);
	},

	getSelectedItems: function (callback) {
		this.messenger.callMethod('getSelectedItems', [callback]);
	},

	getSelectedFolder: function (callback) {
		this.messenger.callMethod('getSelectedFolder', [callback]);
	},

	getQuery: function (callback) {
		this.messenger.callMethod('getQuery', [callback]);
	},

	updateQuery: function (query) {
		// TODO: Add a callback for when the query has updated
		this.messenger.callMethod('updateQuery', [query]);
	}
};

module.exports = Grid;
