import { SET_CHANNEL_NAME, SET_CHANNEL_ID, SET_CHANNEL_GET_STATUS } from '../constants';

export const setChannelName = (arg) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_NAME,
		payload: arg
	});
};
export const setChannelId = (arg) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_ID,
		payload: arg
	});
};
export const setChannelGetStatus = (arg) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_GET_STATUS,
		payload: arg
	});
};
