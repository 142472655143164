/* =============================================================== */
/* MESSAGE CONFIGURATION React component                           */
/* =============================================================== */
/* eslint-disable react/jsx-wrap-multilines,react/jsx-props-no-spreading,react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as channelActions from '../../actions/channels';
import { hasProperty } from '../../utilities/helper';
import TooltipInfo from './TooltipInfo';

// message Config React Class
export class MessageConfig extends React.Component {
	constructor (props) {
		super(props);
		// Don't call this.setState() here!
		this.state = {};

		if (this.props && this.props.scm) {
			this.scm = this.props.scm;
		}
		if (this.scm && this.scm.config) {
			this.store = this.scm.config.store; // eslint-disable-line react/no-unused-class-component-methods
		}

		this.onChangeChannel = this.onChangeChannel.bind(this);
	}

	static renderOption (el, index) {
		const customAtts = {};

		let renderResult = '';

		if (el && hasProperty(el, 'ottPlatformAttributes')) {
			renderResult = (
				<option
					{...customAtts}
					key={index}
					value={el.ottPlatformAttributes.channelId}
				>
					{el.ottPlatformAttributes.channelName}
				</option>);
		}

		return renderResult;
	}

	onChangeChannel (e) {
		// -- if there is no id actually we should just show default summary view
		let selectedChannel = {};

		this.scm.store.getState().channels.forEach((el) => {
			if (el && hasProperty(el, 'ottPlatformAttributes')) {
				if (el.ottPlatformAttributes.channelId === e.target.value) {
					selectedChannel = el;
				}
			}
		});
		if (selectedChannel) {
			this.scm.config.store.dispatch((channelActions.setChannelId(selectedChannel.ottPlatformAttributes.channelId)));
			this.scm.config.store.dispatch((channelActions.setChannelName(selectedChannel.ottPlatformAttributes.channelName)));
		}
	}

	retryGetChannels = () => {
		this.props.getChannels();
	};

	render () {
		let spinnerHtml = '';
		let defaultChannelId = '';
		let defaultChannelLabel = this.scm.i18n.get('select_channel_dots');

		if (this.props.channelId) {
			defaultChannelId = this.props.channelId;
		}
		if (this.props.channelName) {
			defaultChannelLabel = this.props.channelName;
		}

		const channelOptions = this.props.channels.length ? this.props.channels.map((el, index) => this.constructor.renderOption(el, index))
			: <option value={defaultChannelId}>{defaultChannelLabel}</option>;

		// console.log('props - ', this.props);

		if (!this.props.channels.length) {
			spinnerHtml = (
				<span role="status" className="slds-spinner slds-spinner_small slds-spinner_inline spinner-for-channels">
					<span className="slds-assistive-text">{this.scm.i18n.get('loading')}</span>
					<span className="slds-spinner__dot-a" />
					<span className="slds-spinner__dot-b" />
				</span>
			);
		}

		let channelSelectionSection = '';

		if (this.props.channelGetStatus === 'success' && !this.props.channels.length) {
			let adminInfo = '';
			if (!this.scm.store.getState().setPerms) {
				adminInfo = (
					<div className="slds-align_absolute-center slds-p-top_small">
						{this.scm.i18n.get('jb_line_message_config_zero_state_bu')}
					</div>
				);
			} else {
				adminInfo = (
					<div className="slds-align_absolute-center slds-p-top_small">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://${this.scm.sdk.endpoints.stackHost}/cloud/#app/Setup/GroupConnect`}
							className="slds-button slds-button_brand"
						>
							{this.scm.i18n.get('jb_line_setup_channels')}
						</a>
					</div>
				);
			}
			channelSelectionSection = (
				<div>
					<img className="slds-align_absolute-center" src="/images/unconfigured-LINE.svg" alt="channels unconfigured" />
					<div className="slds-align_absolute-center slds-p-top_small slds-p-bottom_small">
						<h3 className="slds-text-heading_medium">{this.scm.i18n.get('jb_line_message_config_zero_state')}</h3>
					</div>
					{adminInfo}
				</div>
			);
		} else {
			channelSelectionSection = (
				<div>
					<div className="slds-float--left">
						<h3
							className="slds-text-heading_label slds-m-bottom_x-small"
						>
							{this.scm.i18n.get('send_message_from_this_line_channel')}
						</h3>
					</div>
					<div className="slds-float--left">
						<TooltipInfo content={this.scm.i18n.get('jb_line_hb_messageconfig')} />
					</div>
					<br />

					<div className="slds-p-top--xx-small">
						<span className="">{this.scm.i18n.get('channel')}</span>
						<span className="required-identifier slds-required slds-p-around_none slds-m-around_none">*</span>

						<div className="slds-form-element">
							<div className="slds-form-element__control">
								<div className="">
									{(this.props.channelGetStatus === 'error') ? (<div className="retry-get-channels-container">
										<div id="code-container">
											{' '}
											<span
												className="fetch-failed-error-message"
											>
												{this.scm.i18n.get('error_getting_channels')}
											</span>
											{' '}
											<button
												onClick={this.retryGetChannels}
												type="button"
												className="slds-button slds-button_brand retry-fetch-channels"
											>
												{this.scm.i18n.get('retry')}
												<svg className="slds-button__icon slds-button__icon_right" aria-hidden="true">
													{' '}
													<use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
													{' '}
												</svg>
												{' '}

											</button>
											{' '}
										</div>
									</div>
									) : ''}
									<select
										disabled={((this.scm.sdk && this.scm.sdk.activity && this.scm.sdk.activity.editable === false) ? ' disabled="true"' : '')}
										value={defaultChannelId}
										onChange={this.onChangeChannel}
										className="slds-select slds-size--1-of-3"
										id="select-channel"
										placeholder={defaultChannelLabel}
									>
										{channelOptions}
									</select>
									{(this.props.channelGetStatus === 'pending') ? spinnerHtml : ''}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="message-config">
				<div className="slds-container--left">
					<div className="slds-text-body_regular slds-m-bottom_x-small">
						<em>{this.scm.i18n.get('define_how_the_message_appears_on_mobile')}</em>
					</div>
					<br />
					{channelSelectionSection}
				</div>
			</div>
		);
	}
}

MessageConfig.propTypes = {
	scm: PropTypes.object.isRequired,
	channelGetStatus: PropTypes.string,
	channelId: PropTypes.string,
	channelName: PropTypes.string,
	channels: PropTypes.array,
	getChannels: PropTypes.func
};
const mapStateToProps = (state) => ({
	channelGetStatus: state.channelGetStatus,
	channels: state.channels,
	channelName: state.channelName,
	channelId: state.channelId
});

export default connect(mapStateToProps)(MessageConfig);
// export default MessageConfig;
