const Message = require('./message');
const Constants = require('./constants');

/**
 * Parent class constructor
 *
 * @param {Element} element - Element to append the iFrame to
 * @param {String} iFrameSource - iFrame source to load
 * @param {Object} handshakePayload - The initial settings to pass through to the child iFrame
 * @param {function=} onReady - Called once the handshake has been completed
 */
const Parent = function (element, iFrameSource, handshakePayload = {}, onReady = function () {}) {
	this._callbacks = {};
	this._handshakePayload = handshakePayload;
	this._isReady = false;
	this._messageQueue = [];
	this._onReady = () => {
		this._sendQueuedMessages();
		onReady();
	};
	this.$el = {
		container: element,
		iFrame: null
	};
	this.instanceId = String(Math.random());

	// Used in order to remove the listener in the destroy method
	this._boundReceiveMessage = this._receiveMessage.bind(this);

	// Make sure that the event listener is created before the source is added
	window.addEventListener('message', this._boundReceiveMessage, false);

	const defaultStyles = {
		width: '100%',
		height: '100%',
		display: 'block'
	};

	const { frameOptions = {} } = handshakePayload;
	const { title, className, style } = frameOptions;

	// Set up the iFrame
	const iFrame = document.createElement('iframe');
	iFrame.name = `crossdocmessenger_${this.instanceId}`;
	iFrame.title = title;
	iFrame.className = className;
	iFrame.src = iFrameSource;

	const merge = Object.assign || function (target) {
		for (let i = 1; i < arguments.length; i++) {
			var source = arguments[i];
			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}
		return target;
	};

	merge(iFrame.style, defaultStyles, style);

	// For IE
	iFrame.width = iFrame.style.width;
	iFrame.height = iFrame.style.height;
	iFrame.frameBorder = '0';
	this.$el.container.appendChild(iFrame);

	this.$el.iFrame = iFrame;
};

Parent.prototype = {
	constructor: Parent,

	/**
	 * Removes the postMessage listener and iFrame
	 */
	destroy: function () {
		window.removeEventListener('message', this._boundReceiveMessage);
		this.$el.container.removeChild(this.$el.iFrame);
	},

	/**
	 * Call a method on the child frame
	 *
	 * @param {String} name - Name of the method to call
	 * @param {[*]=} parameters - Parameters to pass to the method
	 */
	callMethod: function (name, parameters = []) {
		if (!name) {
			throw new Error('A method name must be specified.');
		}
		this._sendMessage(new Message(Message.TYPE.FUNCTION, {
			'name': name,
			'params': parameters
		}));
	},

	/**
	 * Sends a message to the child iFrame
	 *
	 * @param {Message} message - The Message to send
	 * @private
	 */
	_sendMessage: function (message) {
		if (!this._isReady && message.type !== Message.TYPE.HANDSHAKE) {
			// Only allow handshake methods through if we aren't ready yet
			this._messageQueue.push(message);
			return;
		}

		message.send(this.instanceId, this.$el.iFrame.contentWindow, this._targetOrigin, this._callbacks);
	},

	_sendQueuedMessages: function () {
		this._isReady = true;
		this._messageQueue.forEach((message) => this._sendMessage(message));
		this._messageQueue = [];
	},

	/**
	 * Validates and processes a message that has been sent over postMessage
	 *
	 * @param {Object} event - A postMessage event sent from the 'message' listener
	 * @private
	 */
	_receiveMessage: function (event) {
		// Only allow messages from the created child iframe
		const isFromChildWindow = event && event.source === this.$el.iFrame.contentWindow;
		// Only process objects
		const isObject = event && typeof event.data == 'object';
		const isExpectedDataSource = isObject && event.data.source === Constants.SOURCE;

		if (!isObject || !isFromChildWindow || !isExpectedDataSource) {
			return false;
		}

		const message = Message.fromJSON(event.data, this._sendMessage.bind(this));

		if (message.instanceId !== this.instanceId) {
			// The message was not intended for this instance, but that doesn't make it an error
			return false;
		}

		if (message.type !== Message.TYPE.HANDSHAKE) {
			if (event.origin !== this._targetOrigin) {
				throw new Error(`The message was blocked due to "${event.origin}" not being a trusted domain"`);
			}
		}

		// Handle the message
		switch (message.type) {
			case Message.TYPE.HANDSHAKE: {
				this._targetOrigin = event.origin;

				// Set a custom _onReady callback that will be called when the handshake is complete
				this._handshakePayload._onReady = this._onReady;
				// Send a message back to the child to finish the handshake
				const handshakeMessage = new Message(Message.TYPE.HANDSHAKE, this._handshakePayload);

				this._sendMessage(handshakeMessage);
				break;
			}
			case Message.TYPE.FUNCTION: {
				throw new Error('Functions not supported at the parent level.');
			}
			case Message.TYPE.CALLBACK: {
				if (!message.payload.callbackId) {
					throw new Error('Invalid message structure. A callback message must contain a "callbackId" property.');
				}

				const originalCallback = this._callbacks[message.payload.callbackId];

				if (!originalCallback) {
					throw new Error('Invalid message structure. The given callbackId is invalid.');
				}

				// The parameters come across as an object, but they need to be an array
				const paramArray = Object.keys(message.payload.params).map(function (key) {
					return message.payload.params[key];
				});

				// Finally call the callback
				// TODO: This will probably lose the context of the callback which could cause issues
				originalCallback.apply(originalCallback, paramArray);

				break;
			}
			default: {
				throw new Error('Unsupported message type');
			}
		}
	}
};

module.exports = Parent;
