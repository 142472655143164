const initialState = {
	channels: [
	],
	channelGetStatus: 'init',
	assetId: '',
	channelId: '',
	channelName: '',
	activity: {
		name: '',
		description: ''
	},
	selectedMessage: {}
};

export default initialState;
