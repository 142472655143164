const { Parent } = require('@salesforce-mc/crossdocmessenger');
const Url = require('../../utilities/url');

/**
 * Content Generator as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load the Content Generator service from
 * @param {object=} options - Options to initialize the Content Generator service with
 * @param {function=} onReady - Callback to call once the Content Generator service is ready
 * @constructor
 */
const ContentGenerator = function (element, stack, options = {}, onReady = () => {}) {
	const url = Url.GenerateUrl(stack, 'contentgenerator/');
	const finalOptions = { ...this.defaults, ...options };

	this.messenger = new Parent(element, url, finalOptions, onReady);
};

ContentGenerator.prototype = {
	constructor: ContentGenerator,

	defaults: {
		sampleMessage: undefined,
		onMesasgeSelected: undefined,
		onReset: undefined
	},

	/**
   ******************** PUBLIC API ********************
   */
	destroy: function () {
		this.messenger.destroy();
	}
};

module.exports = ContentGenerator;
