export default function (dom, configs) {
	const { content, previewHtml, scm } = configs;

	const renderPreviewer = () => {
		const iframe = document.createElement('iframe'); // eslint-disable-line no-undef

		iframe.style.border = 'none';
		iframe.style.width = '294px';
		iframe.style.height = '522px';
		iframe.style.margin = '0 auto';

		iframe.onload = function () {
			const iframeElementContainer = iframe.contentDocument;

			// IE will trigger another load event on closing the contentDocument
			// That must be stopped!
			iframe.onload = null;

			iframeElementContainer.open();
			iframeElementContainer.writeln(previewHtml);
			iframeElementContainer.close();
		};

		dom.querySelector('.detail-preview-box').appendChild(iframe);
	};

	const render = () => {
		dom.innerHTML = `
			<div class="slds-box detail-wrapper">
				<div class="header detail-view-header">
					<div class="message-name"><b>${content.name}</b></div>
					<div class="message-type">${scm.i18n.values.jb_line_message_type}</div>
					<div class="warning slds-text-color_inverse-weak">${scm.i18n.values.jb_line_no_personalization}</div>
				</div>
				<div class="detail-preview-box"></div>
			</div>
		`;

		renderPreviewer();
	};

	const initialize = () => {
		render();
	};

	initialize();
}
