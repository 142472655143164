const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * API as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to, element should be hidden
 * @param {string} stack - Which stack to load the API service from
 * @param {object=} options - Options to initialize the API service with
 * @param {function=} onReady - Callback to call once the API service is ready
 * @constructor
 */
const API = function (element, stack, options = {}, onReady = function () {}) {
	const finalOptions = $.extend(true, {}, this.defaults, options);
	this.options = finalOptions;

	const url = Url.GenerateUrl(stack, 'api');

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

API.prototype = {
	constructor: API,

	defaults: {},

	/**
	 ******************** PUBLIC API ********************
	 */
	getAsset: function (assetId, onSuccess, onError) {
		this.messenger.callMethod('getAsset', [assetId, onSuccess, onError]);
	},

	destroy: function () {
		this.messenger.destroy();
	}

	// TODO: API: Implement public API
};

module.exports = API;
