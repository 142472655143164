const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load Preview from
 * @param {object=} options - Options to initialize Preview with
 * @constructor
 */
const SubscriberPreview = function (element, stack, options = {}, onReady = function () {}) {
	const url = this._generateUrl(stack);

	const finalOptions = $.extend(true, {}, options, {subscriberPreviewMode: true});

	const hasAssetId = finalOptions.asset.id;
	const hasAssetType = finalOptions.asset.assetType && (finalOptions.asset.assetType.id || finalOptions.asset.assetType.name);
	if (!hasAssetId && !hasAssetType) {
		throw new Error('An asset ID must be provided');
	}

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

SubscriberPreview.prototype = {
	constructor: SubscriberPreview,

	/**
	 * Generates the Cloud Editor Service URL based on the current url/stack
	 *
	 * @return {string}
	 * @private
	 */
	_generateUrl: function (stack) {
		return Url.GenerateUrl(stack, 'oneeditor/');
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		// Destroying the iFrame will destroy everything
		this.messenger.destroy();
	}
};

module.exports = SubscriberPreview;
