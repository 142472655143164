const { Parent } = require('@salesforce-mc/crossdocmessenger');
const Url = require('../../utilities/url');

/**
 * Object Manager as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load the Object Manager service from
 * @param {object=} options - Options to initialize the Object Manager service with
 * @param {function=} onReady - Callback to call once the Object Manager service is ready
 * @constructor
 */
const ObjectManager = function (element, stack, options = {}, onReady = () => {}) {
	const url = Url.GenerateUrl(stack, 'objectmanager/');
	const finalOptions = { ...this.defaults, ...options };

	this.messenger = new Parent(element, url, finalOptions, onReady);
};

ObjectManager.prototype = {
	constructor: ObjectManager,

	defaults: {
		provider: 'local'
	},

	/**
	 ******************** PUBLIC API ********************
	 */
	destroy: function () {
		this.messenger.destroy();
	},

	refresh: function () {
		this.messenger.callMethod('refresh', []);
	},

	getSelectedFolder: function (callback) {
		this.messenger.callMethod('getSelectedFolder', [callback]);
	}
};

module.exports = ObjectManager;
