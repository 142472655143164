const { Parent } = require('@salesforce-mc/crossdocmessenger');
const { GeneratePreviewUrl } = require('../../utilities/url');

/**
 * Preview as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load the Preview service from
 * @param {object=} options - Options to initialize the Preview service with
 * @param {function=} onReady - Callback to call once the Preview service is ready to set content
 * @constructor
 */
const SecurePreview = function (element, stack, options = {}, onReady = function () {}) {
	const finalOptions = { ...this.defaults, ...options };

	const url = GeneratePreviewUrl(stack);

	this.messenger = new Parent(element, url, finalOptions, onReady);
};

SecurePreview.prototype = {
	constructor: SecurePreview,

	defaults: {},

	/**
	 ******************** PUBLIC API ********************
	 */
	setContent: function (content, options = {}, callback = null) {
		this.messenger.callMethod('setContent', [content, options, callback]);
	},

	destroy: function () {
		this.messenger.destroy();
	}
};

module.exports = SecurePreview;
