const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load
 * @param {object=} options - Options to initialize
 * @param {function=} onReady - Callback to call
 * @constructor
 */
const OneSelector = function (element, stack, options = {}, onReady = function () {}) {
	const url = Url.GenerateUrl(stack, 'oneselector/');

	const finalOptions = $.extend(true, {}, this.defaults, options);

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

OneSelector.prototype = {
	constructor: OneSelector,

	defaults: {
		assets: {}
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		// Destroying the iFrame will destroy everything
		this.messenger.destroy();
	}
};

module.exports = OneSelector;
