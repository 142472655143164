/* =============================================================== */
/* SUMMARY VIEW                                                    */
/* =============================================================== */

import Previewer from '@salesforce-mc/mobile-previewer';
import * as types from '../../constants';
import SummaryDetail from './SummaryDetail';

export default function createSummaryView (scm) { // eslint-disable-line class-methods-use-this
	const { activity } = scm.sdk;

	const mapToPreviewKeys = function (objArray, imageAspectRatio, imageSize) {
		const resultArr = objArray.map((objItem) => {
			const container = {};

			if (Object.prototype.hasOwnProperty.call(objItem, 'display:media:url')) {
				container.mediaUrl = objItem['display:media:url'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'display:title')) {
				container.title = objItem['display:title'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'display:message')) {
				container.message = objItem['display:message'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'button1:title')) {
				container.button1 = objItem['button1:title'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'button2:title')) {
				container.button2 = objItem['button2:title'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'button3:title')) {
				container.button3 = objItem['button3:title'];
			}
			if (Object.prototype.hasOwnProperty.call(objItem, 'messageStyle:backgroundColor')) {
				container.backgroundColor = objItem['messageStyle:backgroundColor'].hex;
			}
			if (imageAspectRatio) {
				container.imageAspectRatio = imageAspectRatio;
			}
			if (imageSize) {
				container.imageSize = imageSize;
			}

			return container;
		});

		return resultArr;
	};

	return {
		size: 'scm-lg',
		persistent: false,
		header: {
			text: scm.i18n.get('line_message_activity_summary')
		},
		menu: {
			hidden: true,
			text: 'Summary'
		},
		footer: {
			buttons: [
				{
					text: scm.i18n.get('save_changes'),
					onClick: 'onSaveChangesClicked'
				}
			]
		},
		onInitialize: function () {
			this.setActivityName(activity.name);
			scm.store.dispatch({
				type: types.ACTIVITY_SET_NAME,
				payload: activity.name
			});

			if (activity.metaData && activity.metaData.globals) {
				this.setDescription(activity.metaData.globals.description);
				scm.store.dispatch({
					type: types.ACTIVITY_SET_DESCRIPTION,
					payload: activity.metaData.globals.description
				});
			}
			this.updateDoneButton();
		},
		onActivityNameUpdated: async function (e) {
			await scm.store.dispatch({
				type: types.ACTIVITY_SET_NAME,
				payload: e.value
			});
			this.updateDoneButton();
		},
		onDetailViewClicked: function () {
			this.toggleDetailView();
		},
		onDetailedViewOpened: function (e, args) {
			const { content } = args.store.getState().selectedMessage;
			const { element } = e;
			const imageAspectRatio = content.views.lINE.meta.options.customBlockData.globalSettings ? content.views.lINE.meta.options.customBlockData.globalSettings.imageAimageAspectRatio : undefined;
			const imageSize = content.views.lINE.meta.options.customBlockData.globalSettings ? content.views.lINE.meta.options.customBlockData.globalSettings.imageSize : undefined;
			const previewColumns = mapToPreviewKeys(
				content.views.lINE.meta.options.customBlockData.contents,
				imageAspectRatio,
				imageSize
			);
			const configLine = {
				channel: 'line',
				viewColumn: 1,
				columns: previewColumns
			};

			const previewer = new Previewer(configLine);
			const outputHtmlPreview = previewer.getPage();

			this.summaryDetailView = new SummaryDetail(element, {
				content,
				previewHtml: outputHtmlPreview,
				scm: scm
			});
		},
		onDescriptionUpdated: function (e) {
			scm.store.dispatch({
				type: types.ACTIVITY_SET_DESCRIPTION,
				payload: e.value
			});
		},
		onHide: () => {
		},
		onShow: function () {
			this.updateDoneButton();

			this.setActivityName(scm.store.getState().activity.name);
			this.setDescription(scm.store.getState().activity.description);
		},
		isConfigured: function () {
			const isHubConfigured = Boolean(scm.store.getState().activity.name);
			let isChannelIdSet;
			if (typeof scm.store.getState().channelId !== 'undefined') {
				isChannelIdSet = scm.store.getState().channelId.length;
			} else {
				isChannelIdSet = false;
			}
			return isHubConfigured && scm.xt.areSpokesConfigured()
				&& typeof scm.store.getState().selectedMessage.content !== 'undefined'
				&& !!isChannelIdSet;
		},
		updateDoneButton: function () {
			if (this.isConfigured()) {
				scm.enableFooterButton('done-btn');
			} else {
				scm.disableFooterButton('done-btn');
			}
		},
		onDoneClicked: () => {
			const { sdk } = scm;
			const activityAssetId = (typeof scm.store.getState().assetId !== 'undefined')
				? scm.store.getState().selectedMessage.content.id : null;

			if (activityAssetId && scm.store.getState().channelId !== '') {
				activity.configurationArguments = {
					...activity.configurationArguments,
					selectedMessage: scm.store.getState().selectedMessage,
					description: scm.store.getState().activity.description,
					channelName: scm.store.getState().channelName,
					channelId: scm.store.getState().channelId,
					assetId: activityAssetId
					// channelId: 1398471911 // for testing
					// assetId: 296895  // for testing
				};
				activity.type = 'SendToLineSync';
				activity.name = scm.store.getState().activity.name;
				sdk.setGlobals('channelName', scm.store.getState().channelName);
				sdk.setGlobals('description', scm.store.getState().activity.description);
				sdk.setGlobals('name', scm.store.getState().activity.name);

				// console.log('%c we are leaving the Drawer and entering JB, lets see what is saved..', 'background-color: #dadada; color: #440000;', sdk);

				sdk.saveAndClose();
				scm.xt.setDirtyState(false);
				scm.close();
			}
		}
	};
}
