import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import axios from 'axios';
import { baseUrl } from '../../utilities/baseurl';
import ConnectedMessageConfig from '../react-components/MessageConfig';
import { GET_CHANNELS, SET_CHANNEL_GET_STATUS, SET_CHANNEL_NAME, SET_CHANNEL_ID } from '../../constants';
import { sanitizeHTML } from '../../utilities/helper';

function MessageConfigView (scm) {
	const getChannels = () => new Promise((resolve) => {
		let channels = [];

		const { i18n, store } = scm;

		function getAjaxPages (argurl) {
			axios(argurl)
				.then((res) => {
					if (res) {
						if (res.data && res.data.items && res.data.items.length) {
							channels = channels.concat({
								ott: '',
								ottPlatformAttributes: {
									channelName: i18n.get('select_channel_dots'),
									channelId: '',
									isActive: true
								}
							});
						}

						// -- we filter the channels by isActive property
						channels = channels.concat(res.data.items).filter((ch) => ch.ottPlatformAttributes.isActive === true);

						if (res.data && res.data.links && res.data.links.next) {
							getAjaxPages(`${baseUrl}/fuelapi/ott/${res.data.links.next.href}`);
						} else {
							store.dispatch({
								type: GET_CHANNELS,
								payload: channels
							});
							store.dispatch({
								type: SET_CHANNEL_GET_STATUS,
								payload: 'success'
							});
							resolve();
						}
					}
				})
				.catch((err) => {
					let responseText = 'error';
					if (err.response) {
						responseText = err.response.data;
					}
					store.dispatch({
						type: SET_CHANNEL_GET_STATUS,
						payload: 'error',
						responseText: responseText
					});
					resolve();
				});
		}

		getAjaxPages(`${baseUrl}/fuelapi/ott/v1/registration/line`);
	});

	getChannels();

	return {
		// set to true to see how the behavior changes
		persistent: false,

		header: {
			text: scm.i18n.get('message_configuration')
		},
		menu: {
			text: scm.i18n.get('message_configuration')
		},
		footer: {
			cancel: {
				text: scm.i18n.get('cancel')
			}
		},
		spoke: {
			index: 2,
			disabled: true,
			required: true,
			view: 'messageConfig',
			text: scm.i18n.get('message_configuration'),

			description: () => {
				const isMessageSelected = (scm.store) && scm.store.getState() && scm.store.getState().channelId;
				const { channels, channelId } = scm.store.getState();
				const { store } = scm;

				const isChannelActive = () => scm.store.getState().channels.find((channel, index) => channel.ottPlatformAttributes.channelId === channelId && index !== 0);
				const removeInactiveChannelFromStore = () => {
					store.dispatch({
						type: SET_CHANNEL_NAME,
						payload: ''
					});
					store.dispatch({
						type: SET_CHANNEL_ID,
						payload: ''
					});
					scm.disableFooterButton('done-btn');
				};

				if (!(channels.length > 1)) { // if channels doesn't load first time
					getChannels().then(() => {
						if (isMessageSelected) {
							if (!isChannelActive()) {
								removeInactiveChannelFromStore();
								document.querySelector('.hub-spoke__description').innerText = scm.i18n.get('define_how_the_message_appears_on_mobile'); // eslint-disable-line no-undef
							}
						}
					});
				}

				if (isMessageSelected) {
					let lineAccountChannelName = '';

					if (channels.length) {
						if (!isChannelActive()) {
							removeInactiveChannelFromStore();
							return scm.i18n.get('define_how_the_message_appears_on_mobile');
						}
					}

					if (scm.store.getState().channelName) {
						lineAccountChannelName = scm.store.getState().channelName;
					}

					if (lineAccountChannelName) {
						return `<div class="scm-metadata-item scm-flex"><span class="scm-metadata-item_title slds-m-right_x-small slds-text-color_inverse-weak">${scm.i18n.get('line_account')}</span><span class="scm-metadata-item_value slds-truncate slds-has-flexi-truncate" title="current line account">${sanitizeHTML(lineAccountChannelName)}</span></div>`;
					}
				}

				return scm.i18n.get('define_how_the_message_appears_on_mobile');
			}
		},
		onShow: (scmArg) => {
			const { store } = scmArg;
			const sectionKey = 'message-config';

			const el = document.querySelector('.scm-element[data-view="messageConfig"]'); // eslint-disable-line no-undef
			el.insertAdjacentHTML('beforeend', `<div id="${sectionKey}"></div>`);

			ReactDOM.render(
				<Provider store={store}>
					<IconSettings iconPath="/assets/icons">
						<ConnectedMessageConfig
							scm={scm}
							getChannels={getChannels}
						/>
					</IconSettings>
				</Provider>,
				document.getElementById(sectionKey) // eslint-disable-line no-undef
			);
		}
	};
}

export default MessageConfigView;
