export const hasProperty = (object, property) => Object.prototype.hasOwnProperty.call(object, property);

export const escape = (string) => {
	const escaped = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		"'": '&#39;',
		'"': '&quot;'
	};

	return string.replace(/[&<>'"]/g, (value) => escaped[value]);
};

export const unescape = (string) => {
	const regex = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;
	const unescaped = {
		'&amp;': '&',
		'&#38;': '&',
		'&lt;': '<',
		'&#60;': '<',
		'&gt;': '>',
		'&#62;': '>',
		'&apos;': "'",
		'&#39;': "'",
		'&quot;': '"',
		'&#34;': '"'
	};

	return string.replace(regex, (value) => unescaped[value]);
};

export const sanitizeHTML = (string) => {
	if (!string) {
		return '';
	}

	let html = string;

	if (typeof string !== 'undefined' && string.constructor === String && string.match(/&[^\s]*;/ig) === null) {
		html = unescape(string);
	}

	return escape(html);
};
