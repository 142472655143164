const Constants = require('./constants');

/**
 * Message Constructor
 *
 * @param {String} type - The type of message to send
 * @param {Object=} payload - The payload to send to the other document
 */
const Message = function (type, payload = {}) {
	if (!type) {
		throw new Error('Invalid message structure. Message must contain a "type" property.');
	}

	this.type = type;
	this.payload = payload;
	this.origin = window.location.origin;
	this.instanceId = undefined;
};

Message.prototype = {
	/**
	 * Sends the message to the given window and target
	 *
	 * @param {String} instanceId - The instanceID to send the message to
	 * @param {Window} targetWindow - The Window object to send the message to
	 * @param {String} targetOrigin - The target origin to send the message to
	 * @param {object} callbacks - An object to store the callbacks in
	 */
	send: function (instanceId, targetWindow, targetOrigin, callbacks) {
		this.instanceId = instanceId;
		targetWindow.postMessage(this._toJSON(callbacks), targetOrigin);
	},

	/**
	 * Converts the message to a valid postMessage and sets up the callbacks automatically
	 *
	 * @param {Object} callbacks - Where to store the callback functions
	 * @return {{type: String, origin: String, payload: {}}}
	 * @private
	 */
	_toJSON: function (callbacks) {
		return {
			instanceId: this.instanceId,
			type: this.type,
			origin: this.origin,
			payload: this._callbacksToIds(this.payload, callbacks),
			source: Constants.SOURCE
		};
	},

	/**
	 * Convert all function callbacks to an object with a callbackId
	 *
	 * @param {Object} object - An object with callbacks to convert to callbackIds
	 * @param {Object} callbackObject - A callback object that can store the function callbacks in it
	 * @return {Object} The converted object with callbackIds instead of functions
	 * @private
	 */
	_callbacksToIds: function (object, callbackObject) {
		if (!object) {
			// This is needed because 'null' is an 'object'
			return object;
		}

		if (typeof object === 'object') {
			let result;

			if (object.constructor === Array) {
				result = [];
				for (let i = 0; i < object.length; i++) {
					result[i] = this._callbacksToIds(object[i], callbackObject);
				}
			} else {
				result = {};
				for (const key in object) {
					result[key] = this._callbacksToIds(object[key], callbackObject);
				}
			}

			return result;
		} else if (typeof object === 'function') {
			const callbackId = String(Math.random());
			// Store the callback
			callbackObject[callbackId] = object;

			return {
				callbackId: callbackId
			};
		} else {
			return object;
		}
	},

	/**
	 * Convert callback IDs to functions that send postMessages
	 *
	 * @param {Object} object - A plain object (from JSON) to convert callbackIds to functions
	 * @param {function} sendCallback - A callback function that takes a Message and sends it
	 * @return {Object} Converted object with functions instead of callbackIds
	 * @private
	 */
	_convertIdsToCallbacks (object, sendCallback) {
		if (object instanceof Object) {
			const result = (object.constructor === Array) ? [] : {};

			for (const key in object) {
				if (object[key] && object[key].callbackId) {
					result[key] = function () {
						// Build a callback message
						sendCallback(new Message(Message.TYPE.CALLBACK, {
							callbackId: this.callbackId,
							params: Array.prototype.slice.call(arguments)
						}));
					}.bind(object[key]);
				} else {
					result[key] = this._convertIdsToCallbacks(object[key], sendCallback);
				}
			}

			return result;
		} else {
			return object;
		}
	}
};

/**
 * Creates a message from a JSON postMessage and sets up the callbacks automatically
 *
 * @param {Object} json - JSON from the postMessage
 * @param {function} sendCallback - A callback function that takes a Message and sends it
 * @return {Message}
 */
Message.fromJSON = function (json, sendCallback) {
	if (!json.payload) {
		throw new Error('Invalid message structure. Message must contain a "payload" property.');
	}

	if (!sendCallback) {
		throw new Error('A sendCallback must be specified to handle sending callbacks.');
	}

	if (!json.instanceId) {
		throw new Error('Invalid message structure. Message must contain an "instanceId" property.');
	}

	const message = new Message(json.type, json.payload);

	// Override the message defaults and convert callbackIds
	message.payload = message._convertIdsToCallbacks(json.payload, sendCallback);
	message.origin = json.origin;
	message.instanceId = json.instanceId;

	return message;
};

/**
 * A fake ENUM type for the type of message
 *
 * @readonly
 * @type {{CALLBACK: string, FUNCTION: string, HANDSHAKE: string}}
 */
Message.TYPE = Object.freeze({
	CALLBACK: 'CALLBACK',
	FUNCTION: 'FUNCTION',
	HANDSHAKE: 'HANDSHAKE'
});

module.exports = Message;
