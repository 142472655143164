const Message = require('./message');
const Constants = require('./constants');

/**
 * Child class constructor
 *
 * @param {function=} onReady - A callback to call when the handshake has completed
 */
const Child = function (onReady = function () {}) {
	this._callbacks = {};
	this._handler = undefined;
	const match = /crossdocmessenger_([0-9.]*)/g.exec(window.name);
	if (!match || !match[1]) {
		// This should never happen since the iFrame is created by the Parent
		throw new Error('Invalid iFrame name.');
	}
	this.instanceId = match[1];
	this._onReady = onReady;
	this._sendMessage(new Message(Message.TYPE.HANDSHAKE), '*');

	// Used in order to remove the listener in the destroy method
	this._boundReceiveMessage = this._receiveMessage.bind(this);

	window.addEventListener('message', this._boundReceiveMessage, false);
};

Child.prototype = {
	constructor: Child,

	/**
	 * Removes the postMessage listener
	 */
	destroy: function () {
		window.removeEventListener('message', this._boundReceiveMessage);
	},

	/**
	 * Register a handler to handle the method calls that are passed in
	 *
	 * @param {Object} handler - The object on which method calls are called
	 */
	setHandler: function (handler) {
		this._handler = handler;
	},

	/**
	 * Sends a message to the parent
	 *
	 * @param {Message} message - The Message to send
	 * @param {String=} targetOrigin - The target to send the message to
	 * @private
	 */
	_sendMessage: function (message, targetOrigin = this._parentOrigin) {
		message.send(this.instanceId, window.parent, targetOrigin, this._callbacks);
	},

	/**
	 * Validates and processes a message that has been sent over postMessage
	 *
	 * @param {object} event - A postMessage event sent from the 'message' listener
	 * @private
	 */
	_receiveMessage: function (event) {
		if (typeof event.data !== 'object' || !event.data.source || event.data.source !== Constants.SOURCE) {
			// Only process objects
			return false;
		}

		const message = Message.fromJSON(event.data, this._sendMessage.bind(this));

		if (this.instanceId !== message.instanceId) {
			// The message was not intended for this instance, but that doesn't make it an error
			return false;
		}

		if (event.origin !== this._parentOrigin && message.type !== Message.TYPE.HANDSHAKE) {
			throw new Error(`The message was blocked due to "${event.origin}" not being a trusted domain"`);
		}

		// Handle the message
		switch (message.type) {
			case Message.TYPE.HANDSHAKE: {
				this._parentOrigin = event.origin;
				if (message.payload._onReady) {
					message.payload._onReady();
				}
				this._onReady(message.payload);
				break;
			}
			case Message.TYPE.FUNCTION: {
				this._handleFunction(message);

				break;
			}
			case Message.TYPE.CALLBACK: {
				if (!message.payload.callbackId) {
					throw new Error('Invalid message structure. A callback message must contain a "callbackId" property.');
				}

				const originalCallback = this._callbacks[message.payload.callbackId];

				if (!originalCallback) {
					throw new Error('Invalid message structure. The given callbackId is invalid.');
				}

				// The parameters come across as an object, but they need to be an array
				const paramArray = Object.keys(message.payload.params).map(function (key) {
					return message.payload.params[key];
				});

				// Finally call the callback
				originalCallback.apply(this._handler, paramArray);

				break;
			}
			default: {
				throw new Error('Unsupported message type');
			}
		}
	},

	/**
	 * Handles a function message
	 *
	 * @param {Message} message - A Message object to handle
	 * @private
	 */
	_handleFunction: function (message) {
		const payload = message.payload;

		if (!this._handler) {
			throw new Error('Function handler not set. Ensure that setHandler() is called after initializing the child component.');
		}

		if (!payload.name) {
			throw new Error('Invalid message structure. A function message must contain a "name" property.');
		}

		payload.params = payload.params || {};

		// The parameters come across as an object, but they need to be an array
		const paramArray = Object.keys(payload.params).map(function (key) {
			return payload.params[key];
		});

		// Call the actual function!
		return this._handler[payload.name].apply(this._handler, paramArray);
	}
};


module.exports = Child;
