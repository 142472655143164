import * as types from '../../constants';

const moment = require('moment');
const CardIconHtml = require('../../templates/card-icon.html');

export default function createMessageDefinitionView (scm) {
	const config = scm.sdk.activity.configurationArguments;

	return {

		onContentDeselected: (e) => {
			scm.store.dispatch({ // eslint-disable-line no-undef
				type: types.DESELECT_MESSAGE,
				payload: e
			});
			scm.store.dispatch({ // eslint-disable-line no-undef
				type: types.SET_ASSET_ID,
				payload: ''
			});
		},
		onContentChanged: function (e) {
			// when new content is selected, the previous
			// message config is no longer valid
			if (!e.content || config.assetId !== e.content.id) {
				config.messageConfig = {};
			}

			scm.store.dispatch({ // eslint-disable-line no-undef
				type: types.SELECT_MESSAGE,
				payload: e
			});

			if (e.content) {
				config.assetId = e.content.id;
				scm.xt.enableSpoke('messageConfig');
				scm.store.dispatch({ // eslint-disable-line no-undef
					type: types.SET_ASSET_ID,
					payload: e.content.id
				});

				// save activity name with content name
				scm.store.dispatch({
					type: types.ACTIVITY_SET_NAME,
					payload: e.content.name
				});
			} else {
				config.assetId = null;
				scm.xt.disableSpoke('messageConfig');
				scm.store.dispatch({ // eslint-disable-line no-undef
					type: types.SET_ASSET_ID,
					payload: ''
				});
			}
		},
		onRenderMessageCard: function (e, args) {
			const { content } = e;

			e.cardOptions.cardIconHtml = (typeof CardIconHtml === 'string' || CardIconHtml instanceof String) ? CardIconHtml : CardIconHtml.default;

			const cultureCode = (scm.config.cultureCode && scm.config.cultureCode) ? scm.config.cultureCode : 'en';
			moment.locale(cultureCode);

			e.cardOptions.metadata.values = [
				{ text: scm.i18n.get('last_modified'), value: moment(content.modifiedDate).format('LL') },
				{ text: scm.i18n.get('created_by'), value: content.createdBy.name },
				{ text: scm.i18n.get('created_date'), value: moment(content.createdDate).format('LL') },
				{ text: scm.i18n.get('status'), value: scm.i18n.get(`majik_label_${String(content.status.name).toLowerCase()}`) }
			];

			if (args.isReadOnly()) {
				// Update card button options - Only show subscriber preview
				e.cardOptions.dropdownList = [];
			}
		},
		onRenderLegacyContentArea: function () {
		},
		onContentBuilderTabSelected: function () {
			if (this.shouldRefreshContentBuilderGrid) {
				// content builder does not support deselecting content
				// so we need to manually refresh the grid to clear the selection
				this.refreshContentBuilderGrid();
				this.shouldRefreshContentBuilderGrid = false;
			}
		},
		onLegacyContentSelected: function (e) {
			this.setContent(e.content);
			config.legacyContent = e.content;
			this.shouldRefreshContentBuilderGrid = true;
		},
		onContentSelected: function () {
			config.isLegacyContent = null;
		}
	};
}
