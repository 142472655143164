/* =============================================================== */
/* ReactExtension (experimental)                                   */
/* =============================================================== */

function MAJIKReactExtension (scm, options) {
	const delegates = {};
	const { React, ReactDOM } = options;

	function renderComponent (view) {
		view.onUpdateComponent();
		const ReactComponent = view.component;
		const props = view.props || {};
		const reactElement = React.createElement(ReactComponent, props);
		ReactDOM.render(reactElement, scm.element, function () {
			view.ref = this; // save the component reference
		});
	}

	delegates.onBeforeViewInitialize = function (e) {
		const { view } = e;
		if (view.component) {
			// Add a new function to the view: onUpdateComponent()
			// This callback will be triggered before the react Component renders.
			// It gives developers a chance to update props
			view.onUpdateComponent = view.onUpdateComponent || function noop () {};

			// Add a new function to the view: renderComponent()
			// This function will force the react component to re-render
			view.renderComponent = view.renderComponent || function () {
				renderComponent(this);
			};
		}
	};

	delegates.onViewShow = function (e) {
		const { view } = e;
		if (view.component) {
			// do not re-render if the view is persistent
			if (view.ref && view.persistent) {
				return;
			}
			renderComponent(view);
		}
	};

	delegates.onViewHide = function (e) {
		const { view } = e;
		// do not unmount the component if it is persistent
		if (view.component && !view.persistent) {
			ReactDOM.unmountComponentAtNode(scm.element);
		}
	};

	return delegates;
}

module.exports = {
	MAJIKReactExtension
};
