const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load Cloud Editor from
 * @param {object=} options - Options to initialize Cloud Editor with
 * @param {function=} onReady - Callback to call once Cloud Editor is ready
 * @constructor
 */
const MessageCreate = function (element, stack, options = {}, onReady = function () {}) {
	const url = Url.GenerateUrl(stack, 'messagecreate');

	const finalOptions = $.extend(true, {}, this.defaults, options);

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

MessageCreate.prototype = {
	constructor: MessageCreate,

	defaults: {
		asset: {},
		type: 'email',
		currentTab: 0
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		// Destroying the iFrame will destroy everything
		this.messenger.destroy();
	},

	setCategory: function (id) {
		this.messenger.callMethod('setCategory', [id]);
	},

	setBackText: function (text) {
		this.messenger.callMethod('setBackText', [text]);
	}
};

module.exports = MessageCreate;
