import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { routerActions } from 'react-router-redux';
import MAJIK from '@salesforce-mc/majik/dist/majik';
import PropTypes from 'prop-types';
import ActivitySDK from '@salesforce-mc/activitysdk';
import MAJIKMessageActivity from '@salesforce-mc/majik-message-activity';
import MAJIKChannelAnalytics from '@salesforce-mc/majik-channel-analytics';
import MCLib from '@salesforce-mc/mc-lib';
import { MAJIKReactExtension } from '../utilities/majik-react-extension';
import I18n from '../utilities/i18n';
import PostmongerStore from '../utilities/postmonger';
import * as types from '../constants';

import {
	SummaryView,
	NavIconTemplateView,
	HeaderIconTemplateView,
	DefineMessageView,
	MessageConfigView
} from './majik-views/index';

export class AppBare extends React.Component {
	constructor (props) {
		super(props);
		this.cultureCode = PostmongerStore.cultureCode;
		this.i18n = I18n(this.cultureCode);
		// -- we will use this to add all keys to the dictionary without writing a bunch of lines for each key
		// -- we will use this to add all keys to the dictionary without writing a bunch of lines for each key
		this.localization = {
			majik_hs_show_more: this.i18n.get('majik_button_show_more'),
			majik_hs_show_less: this.i18n.get('majik_button_show_less'),
			majik_hs_cancel_button: this.i18n.get('majik_button_cancel'),
			majik_hs_summary_button: this.i18n.get('majik_button_summary'),
			majik_hs_spoke_view_button: this.i18n.get('majik_button_view'),
			majik_hs_spoke_edit_button: this.i18n.get('majik_button_edit'),
			majik_hs_close_message_confirm: this.i18n.get('majik_button_confirm'),
			majik_hs_close_message_cancel: this.i18n.get('majik_button_cancel'),
			majik_hs_close_message_title: this.i18n.get('majik_label_close_message_title'),
			majik_hs_close_message_body: this.i18n.get('majik_label_close_message_body'),

			// ContentView extension keys:
			majik_ce_message_definition_header: this.i18n.get('message_configuration'),
			majik_ce_message_definition: this.i18n.get('majik_label_message_definition'),
			majik_ce_message_definition_menu: this.i18n.get('majik_label_message_definition_menu'),
			majik_ce_create_message_link: this.i18n.get('majik_button_create_message_link'), // must contain a link!
			majik_ce_edit_message_link: this.i18n.get('majik_button_edit_message_link'), // must contain a link!
			majik_ce_content_builder_tab: this.i18n.get('majik_button_content_builder_messages'),
			majik_ce_activity_name: this.i18n.get('majik_label_activity_name'),
			majik_ce_activity_desc: this.i18n.get('majik_label_activity_description'),
			majik_ce_summary_menu: this.i18n.get('majik_button_summary'),
			majik_ce_detail_view_btn: this.i18n.get('majik_button_detail_view'),
			majik_ce_summary_view_btn: this.i18n.get('majik_button_summary_view'),
			majik_ce_summary_view_message_btn: this.i18n.get('majik_summary_view_message'),
			majik_ce_done_btn: this.i18n.get('majik_button_done'),
			majik_ce_summary_header: this.i18n.get('message_configuration'),
			majik_ce_summary_select_message_btn: this.i18n.get('majik_button_select_message'),
			majik_ce_summary_new_message_btn: this.i18n.get('majik_button_new_message'),
			majik_ce_summary_edit_message_btn: this.i18n.get('majik_button_edit_message'),
			majik_ce_summary_call_to_action_lg: this.i18n.get('majik_label_summary_call_to_action_lg'),
			majik_ce_summary_call_to_action_sm: this.i18n.get('majik_label_summary_call_to_action_sm'),
			majik_ce_save_and_return: this.i18n.get('majik_button_save_and_return'),
			majik_ce_save_message: this.i18n.get('majik_button_save_message'),
			majik_ce_message_properties: this.i18n.get('majik_label_message_properties'),
			majik_ce_edit_properties: this.i18n.get('majik_button_edit_properties'),
			majik_ce_view_properties: this.i18n.get('majik_button_view_properties'),
			majik_select_different_message: this.i18n.get('majik_button_select_different_message'),
			majik_ce_create_new_message: this.i18n.get('majik_button_create_new_message'),
			majik_ce_refresh_activity: this.i18n.get('majik_button_refresh_activity'),
			majik_ce_error_view_main_text: this.i18n.get('majik_label_error_view_main_text'),
			majik_ce_error_view_sub_text: this.i18n.get('majik_label_error_view_sub_text'),
			majik_ce_close_editor_message_body: this.i18n.get('majik_label_close_message_body'),
			majik_ca_line_dock_header: this.i18n.get('jb_line_analytics_dock_header'),
			majik_ce_preview_subscribers: this.i18n.get('majik_button_subscriber_preview'),
			jb_line_message_type: this.i18n.get('jb_line_message_type'),
			created_by: this.i18n.get('majik_label_created_by'),
			last_modified: this.i18n.get('majik_label_metadata_last_modified'),
			status: this.i18n.get('majik_label_metadata_status'),
			created_date: this.i18n.get('majik_label_metadata_created_date'),
			jb_line_no_personalization: this.i18n.get('jb_line_no_personalization')
		};
		const keysFromLocale = {};
		Object.keys(this.i18n.dictionary).forEach((lab) => {
			keysFromLocale[lab] = this.i18n.dictionary[lab];
		});
		this.localization = Object.assign(keysFromLocale, this.localization);
		this.activitySDK = new ActivitySDK();
	}

	initializeMCLib (endpoints) {
		// In QA1SS1, endpoints.stackHost will be `mc.s1.qa1.exacttarget.com`
		const stackHostProtocol = 'https://';
		const allowedOrigin =  stackHostProtocol + endpoints.stackHost;

		this.mcLib = new MCLib({
			mc: window.top, // eslint-disable-line no-undef
			allowedOrigin: allowedOrigin,
			callback: () => {
				setTimeout(() => {
					console.log('MCLib obj =>', this.mcLib);
					console.log('GC: mclib.version = %s', this.mcLib.version);
					console.log('GC: mclib.verifiedMC = %s', this.mcLib.verifiedMC);
					// test postMessage & event listeners

					this.mcLib.registerLogoutUrl(`${window.location.origin}/logout`);
					this.mcLib.getPermissionSet('GroupConnect', (returnedPermissionSet) => {
						console.log('Returned perms => ', returnedPermissionSet);
						const getPerms = (typeof returnedPermissionSet !== 'undefined')
							? returnedPermissionSet.ADMINISTERSMSCHANNEL : false;
						store.dispatch({ // eslint-disable-line no-undef
							type: types.SET_PERMS,
							payload: getPerms
						});
					});
					this.mcLib.on('hasaccess', 'marketing-cloud', (returnedData) => {
						console.log('GC: mclib postMessage response %o', returnedData);
					});
				});
			}
		});
	}

	componentDidMount () {
		const scmXts = [
			{
				extension: MAJIKMessageActivity.MessageActivity,
				options: {
					summary: {
						showDetailViewButton: true
					},
					summaryView: {
						showAnalyticsToggle: true,
						isActivityNameRequired: true
					},
					messageDefinition: {
						showPreviewSubscriber: true,
						showNewMessageButton: true,
						showLegacyMessageTab: false,

						getAssetId: function (scm) {
							const { activity } = scm.sdk;
							if (activity.configurationArguments.legacyContent) {
								return null;
							}
							return activity.configurationArguments.assetId;
						},

						contentBuilder: {
							grid: {
								defaultView: 'thumbnail',
								query: {
									channelViews: ['LINE'],
									acceptedTypes: [230]
								}
							},

							editor: {
								view: 'LINE',
								useEmailEditor: false,
								asset: {
									assetType: {
										id: 230
									}
								}
							},

							previewSubscriber: {
								view: 'LINE',
								previewData: null,
								recipientData: null,
								dataSource: null
							}

						}
					}
				}
			},
			{
				extension: MAJIKReactExtension,
				options: {
					React,
					ReactDOM
				}
			},
			{
				extension: MAJIKChannelAnalytics.Extension,
				options:
					{
						route: 'line/:id/dock',
						getId: function (scm) {
							if (scm.sdk.activity && scm.sdk.activity.id) {
								return scm.sdk.activity.id;
							}
							return 0;
						},
						getStack: function (scm) {
							let stackKeyString = 'qa1s1'; // -- remove after testing in qa1
							if (scm.sdk.endpoints && scm.sdk.endpoints.stackKey) {
								stackKeyString = scm.sdk.endpoints.stackKey;
							}
							return stackKeyString;
						}
					}
			}
		];

		const aSDK = this.activitySDK;
		if (PostmongerStore.devMode) {
			// You don't need the SCMDrawer extension if running in Journey Builder. However,
			// this extension gives us the ability to resize the drawer when running in a regular HTML page.
			// It's good for testing your SCM component when you're running outside of JB.
			// this.scm.registerExtension({
			// 	extension: MAJIK.extensions.SCMDrawer
			// });

			// we're going to override the ready function for this app since
			// nothing is listening to the SDK's postmonger events.
			// you could also setup some fake activity data for local dev

			aSDK.endpoints = {
				stackKey: 'qa1s1',
				qa1s1: {
					fuelapiRestHost: 'https://mcrs26-9ngkw--s5dzx76fpzync4.rest-qa1.marketingcloudqaapis.com/',
					restHost: 'rest.s1.qa1.exacttarget.com',
					ssoUrl: 'https://mc.s1.qa1.exacttarget.com/cloud/tools/SSO.aspx?env=default&legacy=1&sk=QA1S1',
					stackHost: 'mc.s1.qa1.exacttarget.com',
					stackKey: 'QA1S1'
				}
			};
			const aSDKConfig = {
				editable: true,
				// id: '30968363-9942-4601-9461-c7b833624f4b', // -- activity id
				name: 'My Mock Activity',
				metaData: {
					globals: {
						description: 'This is a mock activity'
						// channelName: 'testing'
					}
				},
				configurationArguments: {
					messageId: '311516'
				}
			};
			if (localStorage && localStorage.getItem('assetId')) { // eslint-disable-line no-undef
				aSDKConfig.configurationArguments.assetId = localStorage.getItem('assetId'); // eslint-disable-line no-undef
			}
			aSDK.activity = aSDKConfig;
			this.activitySDK.ready = function (callback) {
				// set your mock activity here
				setTimeout(() => {
					callback(aSDKConfig);
				}, 200);
			};

			// the message activity extension will look at the journey's status to determine
			// if the analytics view should be shown
			this.activitySDK.requestInteraction = function (callback) {
				callback({ status: 'RUNNING' });
			};
		}

		this.scm = new MAJIK.StandardConfigModel({
			element: this.majik,
			defaultView: 'summary',
			cultureCode: this.cultureCode,
			localization: this.localization,
			// we'll use the store property to save common view data (like a selected message).
			// all views will have access to this via scm.store
			// feel free to create your own special class for this, for now we'll just use a plain old object
			store: store, // eslint-disable-line no-undef
			navIcon () {
				return NavIconTemplateView;
			},
			headerIcon () {
				return HeaderIconTemplateView;
			},

			onClose () {
			},
			sdk: this.activitySDK,
			views: {
				summary: SummaryView,
				messageDefinition: DefineMessageView,
				messageConfig: MessageConfigView
			},
			extensions: scmXts
		});

		this.activitySDK.ready(() => {
			if (typeof this.activitySDK !== 'undefined') {
				this.initializeMCLib(this.activitySDK.endpoints);
			}
			// -- if we have data coming from activity, we will change redux store to that
			const sdk = this.activitySDK;
			if (sdk.activity && sdk.activity.configurationArguments) {
				// -- get the values we need from activity into our redux store
				if (sdk.activity.name) {
					store.dispatch({ // eslint-disable-line no-undef
						type: types.ACTIVITY_SET_NAME,
						payload: sdk.activity.name
					});
				}
				if (sdk.activity.metaData && sdk.activity.metaData.globals && sdk.activity.metaData.globals.description) {
					store.dispatch({ // eslint-disable-line no-undef
						type: types.ACTIVITY_SET_DESCRIPTION,
						payload: sdk.activity.metaData.globals.description
					});
				}
				if (sdk.activity.configurationArguments.selectedMessage && sdk.activity.configurationArguments.selectedMessage.id) {
					// -- if we have selected channel then we just configure it ..
					if (this.scm.config.views.messageDefinition && this.scm.config.views.messageDefinition.spoke) {
						this.scm.config.views.messageDefinition.spoke.configured = true; // eslint-disable-line no-undef
					}

					store.dispatch({ // eslint-disable-line no-undef
						type: types.SELECT_MESSAGE,
						payload: sdk.activity.configurationArguments.selectedMessage
					});
				}
				if (sdk.activity.configurationArguments.channelId) {
					store.dispatch({ // eslint-disable-line no-undef
						type: types.SET_CHANNEL_ID,
						payload: sdk.activity.configurationArguments.channelId
					});
				}
				if (sdk.activity.metaData && sdk.activity.metaData.globals && sdk.activity.metaData.globals.channelName) {
					store.dispatch({ // eslint-disable-line no-undef
						type: types.SET_CHANNEL_NAME,
						payload: sdk.activity.metaData.globals.channelName
					});
				}
				if (sdk.activity.configurationArguments.assetId) {
					store.dispatch({ // eslint-disable-line no-undef
						type: types.SET_ASSET_ID,
						payload: sdk.activity.configurationArguments.assetId
					});
				}
			}

			this.scm.render();
		});
	}

	render () {
		return (
			<div
				className="groupconnect-activity"
				ref={(node) => {
					this.majik = node;
				}}
			/>
		);
	}
}

AppBare.displayName = 'JB-GroupConnect';

AppBare.propTypes = {
	children: PropTypes.node,
	endpoints: PropTypes.object
};

function mapStateToProps (state, ownProps) {
	return {
		endpoints: state.endpoints,
		pathname: ownProps.location.pathname
	};
}

function mapDispatchToProps () {
	return {
		...routerActions
	};
}

export default compose(
	hot(module),
	connect(mapStateToProps, mapDispatchToProps)
)(AppBare);
