const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load Cloud Editor from
 * @param {object=} options - Options to initialize Cloud Editor with
 * @param {function=} onReady - Callback to call once Cloud Editor is ready
 * @constructor
 */
const CloudEditor = function (element, stack, options = {}, onReady = function () {}) {
	const url = this._generateUrl(stack, options.useEmailEditor);

	const finalOptions = $.extend(true, {}, this.defaults, options);

	const hasAssetId = finalOptions.asset.id;
	const hasAssetType = finalOptions.asset.assetType && (finalOptions.asset.assetType.id || finalOptions.asset.assetType.name);
	if (!hasAssetId && !hasAssetType) {
		throw new Error('An asset ID or asset type with id or name must be provided');
	}

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

CloudEditor.prototype = {
	constructor: CloudEditor,

	defaults: {
		asset: {}
	},

	/**
	 * Generates the Cloud Editor Service URL based on the current url/stack
	 *
	 * @return {string}
	 * @private
	 */
	_generateUrl: function (stack, useEmailEditor) {
		// This deep link is the magic that allows us to automatically navigate to the editor
		const _deepLink = useEmailEditor === true ? 'editor' : 'oneeditor/';

		return Url.GenerateUrl(stack, _deepLink);
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		// Destroying the iFrame will destroy everything
		this.messenger.destroy();
	},

	getAsset: function (callback) {
		this.messenger.callMethod('getAsset', [callback]);
	},

	setAsset: function (asset, callback) {
		this.messenger.callMethod('setAsset', [asset, callback]);
	},

	saveAsTemplate: function (callback) {
		this.messenger.callMethod('saveAsTemplate', [callback]);
	},

	templateSwitch: function (mode, callback) {
		this.messenger.callMethod('templateSwitch', [mode, callback]);
	},

	save: function (onSuccess, onError) {
		this.messenger.callMethod('save', [onSuccess, onError]);
	},

	isDirty: function (callback) {
		this.messenger.callMethod('isDirty', [callback]);
	}
};

module.exports = CloudEditor;
