const $ = require('jquery');
const ParentMessenger = require('@salesforce-mc/crossdocmessenger').Parent;
const Url = require('../../utilities/url');

/**
 * Content Builder as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load Cloud Editor from
 * @param {object=} options - Options to initialize Cloud Editor with
 * @param {function=} onReady - Callback to call once Cloud Editor is ready
 * @constructor
 */
const ContentProperties = function (element, stack, options = {}, onReady = function () {}) {
	const url = Url.GenerateUrl(stack, 'contentproperties');

	const finalOptions = $.extend(true, {}, this.defaults, options);

	this.messenger = new ParentMessenger(element, url, finalOptions, onReady);
};

ContentProperties.prototype = {
	constructor: ContentProperties,

	defaults: {
		actions: undefined,
		allowFolderCrud: true,
		allowUpload: true,
		columns: undefined,
		defaultView: undefined,
		fullContentBuilder: false,
		multi: true,
		onDeselected: null,
		onLoad: null,
		onSelected: null,
		showCreate: true,
		showEmailHowTo: false,
		showHeader: true,
		showImport: false,
		showItemActions: true,
		title: undefined
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		this.messenger.destroy();
	},

	clearSelectedItems: function (callback) {
		this.messenger.callMethod('clearSelectedItems', [callback]);
	},

	getSelectedItems: function (callback) {
		this.messenger.callMethod('getSelectedItems', [callback]);
	},

	getSelectedFolder: function (callback) {
		this.messenger.callMethod('getSelectedFolder', [callback]);
	},

	getQuery: function (callback) {
		this.messenger.callMethod('getQuery', [callback]);
	},

	updateQuery: function (query) {
		// TODO: Add a callback for when the query has updated
		this.messenger.callMethod('updateQuery', [query]);
	}
};

module.exports = ContentProperties;
