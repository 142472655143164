import svg4everybody from 'svg4everybody';

// required because ie11 does not support loading svg external content
svg4everybody();

if (!window.location.origin) { // eslint-disable-line no-undef
	window.location.origin = `${window.location.protocol}//${window.location.hostname}${// eslint-disable-line no-undef
		window.location.port ? `:${window.location.port}` : '' // eslint-disable-line no-undef
	}`;
}
