const $ = require('jquery');
const { Parent } = require('@salesforce-mc/crossdocmessenger');
const Url = require('../../utilities/url');

/**
 * Tree View as a Service Constructor
 *
 * @param {HTMLElement} element - DOM element to append the iFrame to
 * @param {string} stack - Which stack to load the Object Manager service from
 * @param {object=} options - Options to initialize the Object Manager service with
 * @param {function=} onReady - Callback to call once the Object Manager service is ready
 * @constructor
 */
const TreeView = function (element, api, stack, options = {}, onReady = () => {}) {
	const url = Url.GenerateUrl(stack, 'treeview/');
	const defaults = Object.entries(this.defaults).reduce((acc, [key, val]) => {
		return { ...acc, [key]: typeof val === 'function' ? val.bind(this) : val };
	}, {});
	const finalOptions = $.extend(true, {}, defaults, options);

	this.messenger = new Parent(element, url, finalOptions, onReady);
	this.api = api;
};

TreeView.prototype = {
	constructor: TreeView,

	defaults: {
		title: undefined,
		onSelect: function (payload) {
			this.api.onSelect(payload);
		},
		onRefresh: function (payload) {
			this.api.onRefresh(JSON.stringify(payload, null, 2));
		},
		onLock: function (payload) {
			this.api.onLock(payload);
		},
		onEdit: function (payload) {
			this.api.onEdit(payload);
		},
		onRemove: function (payload) {
			this.api.onRemove(payload);
		},
		onMove: function (id, destinationId, destinationIndex) {
			this.api.onMove(id, destinationId, destinationIndex);
		},
		onDuplicate: function (payload) {
			this.api.onDuplicate(payload);
		}
	},

	/**
	 ******************** PUBLIC API ********************
	 */

	destroy: function () {
		this.messenger.destroy();
	},

	refresh: function (hierarchy) {
		this.messenger.callMethod('refresh', [hierarchy]);
	},

	focus: function (id) {
		this.messenger.callMethod('focus', [id]);
	}
};

module.exports = TreeView;
