const stackMap = {
	's1.qa1': 'qa1s1',
	's1.qa2': 'qa2s1',
	's1.qa3': 'qa3s1',
	's2.qa1': 'qa1s2',
	's2.qa2': 'qa2s2',
	's2.qa3': 'qa3s2',
	's3.qa1': 'qa1s3',
	's3.qa3': 'qa3s3',
	's4.qa1': 'qa1s4',
	's4.qa3': 'qa3s4',
	'boa': 's5',
	'dev2s101': '101.dev',
	'test201': '201.test',
	'stage300': '300.stage'
};

const urlMapFalcon = {
	'101.dev': 'mj-content-builder.101.dev.marketingclouddevapps.com',
	'201.test': 'mj-content-builder.201.test.marketingcloudtestapps.com',
	'300.stage': 'mj-content-builder.300.stage.marketingcloudstageapps.com',
	'400': 'mj-content-builder.400.marketingcloudapps.com',
	'401': 'mj-content-builder.401.marketingcloudapps.com',
	'402': 'mj-content-builder.402.marketingcloudapps.com',
	'403': 'mj-content-builder.403.marketingcloudapps.com',
	'404': 'mj-content-builder.404.marketingcloudapps.com',
	'405': 'mj-content-builder.405.marketingcloudapps.com',
	'406': 'mj-content-builder.406.marketingcloudapps.com',
	'407': 'mj-content-builder.407.marketingcloudapps.com',
	'408': 'mj-content-builder.408.marketingcloudapps.com',
	'409': 'mj-content-builder.409.marketingcloudapps.com'
};

/**
 * Gets the url from localstorage for the service
 *
 * @return {?string}
 */
const GetLocalStorageValue = function (key) {
	try {
		const value = localStorage.getItem(key);
		if (value) {
			// eslint-disable-next-line no-console
			console.log(`Found stack override for ${key}, using ${value}`);
			return value;
		}
	} catch (error) {
		return null;
	}
};

const CreateUrlFromStackKey = function (stack, deepLink) {
	const localstorageStack = GetLocalStorageValue(`contentbuilder_service_${deepLink}`);

	let _stack = localstorageStack || stack.toLowerCase();
	_stack = stackMap[_stack] || _stack;

	if (urlMapFalcon[_stack]) {
		return `https://${urlMapFalcon[_stack]}/${deepLink}`;
	}

	if (_stack === 'localhost') {
		// eslint-disable-next-line no-alert
		const localRoot = globalThis.prompt(`Enter the root URL for ${deepLink}`,'localhost:3000');

		return `https://${localRoot}/${deepLink}`;
	}

	return `https://content-builder.${_stack}.marketingcloudapps.com/${deepLink}`;
};

// Temporary, throwaway code to support CG/Content Generation
const urlMapFalconTemp = {
	'101.dev': 'mj-tags.101.dev.marketingclouddevapps.com',
	'201.test': 'mj-tags.201.test.marketingcloudtestapps.com',
	'300.stage': 'mj-tags.300.stage.marketingcloudstageapps.com',
	'400': 'mj-tags.400.marketingcloudapps.com',
	'401': 'mj-tags.401.marketingcloudapps.com',
	'402': 'mj-tags.402.marketingcloudapps.com',
	'403': 'mj-tags.403.marketingcloudapps.com',
	'404': 'mj-tags.404.marketingcloudapps.com',
	'405': 'mj-tags.405.marketingcloudapps.com',
	'406': 'mj-tags.406.marketingcloudapps.com',
	'407': 'mj-tags.407.marketingcloudapps.com',
	'408': 'mj-tags.408.marketingcloudapps.com',
	'409': 'mj-tags.409.marketingcloudapps.com'
};

// Temporary, throwaway code to support CG/Content Generation
const CreateUrlFromStackKeyTemp = function (stack, deepLink) {
	const localstorageStack = GetLocalStorageValue(`contentbuilder_service_${deepLink}`);

	let _stack = localstorageStack || stack.toLowerCase();
	_stack = stackMap[_stack] || _stack;

	if (urlMapFalconTemp[_stack]) {
		return `https://${urlMapFalconTemp[_stack]}/${deepLink}`;
	}

	if (_stack === 'localhost') {
		// eslint-disable-next-line no-alert
		const localRoot = globalThis.prompt(`Enter the root URL for ${deepLink}`,'localhost:3000');

		return `https://${localRoot}/${deepLink}`;
	}

	return `https://tags-service.${_stack}.marketingcloudapps.com/${deepLink}`;
}

// This will match any URL that has a string like:
// mc.s1.qa1.exacttarget.com
// But we are mostly looking at strings like
// https://mc.s1.qa1.exacttarget.com/cloud/tools/SSO.aspx?appId=2b5b916d-2ee0-4c87-ac35-a2f6475f822e&env=default&legacy=1&sk=QA1S1
const GetStackFromUrl = function (url) {
	const pattern1p = /(?:mc|members)\.(.+)\.exacttarget\.com/;
	const patternFalcon = /\/\/(?:[^.]+)\.(.+)\.(?:exacttarget|marketingcloud(?:dev|test|stage)?apps)\.com/i;
	const patternLocalhost = /^https?:\/\/localhost/;

	const match1P = url.match(pattern1p);
	if (match1P) {
		return match1P[1];
	}

	const matchFalcon = url.match(patternFalcon);
	if (matchFalcon) {
		return matchFalcon[1];
	}

	const matchLocalhost = url.match(patternLocalhost);
	if (matchLocalhost) {
		return 'localhost';
	}

	// s1 doesn't have the stack in the URL so fall back to s1 if not found
	return 's1';
};

const ParseV2Url = function (url) {
	if (url.indexOf('login') != -1) {
		const parsed = url.substring(0, url.indexOf('login'));
		return parsed;
	} else {
		return url
	}
};

module.exports = {
	GetLocalStorageValue,
	CreateUrlFromStackKey,
	CreateUrlFromStackKeyTemp,
	GetStackFromUrl,
	ParseV2Url,
	stackMap
}
