/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

function TooltipInfo (props) {
	return (
		<div className="slds-float--left">
			&nbsp;&nbsp;
			<div className="tooltip">
				<span className="tooltip-text">{props.content}</span>
			</div>
		</div>
	);
}

export default TooltipInfo;

TooltipInfo.propTypes = {
	content: PropTypes.string
};
